//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from "vuex"
import {USERDATA} from "../../store/modulesNames"
import {ACT_REQUEST_MEDIA_DEVICES_ACCESS} from "../../store/actionsTypes"
import {GET_ACTIVE_MICROPHONE} from "../../store/gettersTypes"

import AudioMsgRecorder from "./AudioMsgRecorder.js"
import { createWorkletNode } from "../../common/Audio.js"

export default {
    name: "AudioMsgRecorder",
    props: {
        sendingProgress: {
            type: Number,
            required: false,
            default: 0
        },
    },
    data() {
        return {
            recordingAudioMessage: false,
            recordTime: 0,
            recordAudioInterval: null,
            recordAudioContext: null,
            recordAudioWorkletNode: null,
            mediaRecorder: null,
            canvas: null,
            drawBarsInterval: null,
        }
    },
    watch: {
        sendingProgress(val) {
            if (val === 100) {
                this.disablingWindow.style.display = 'none'
                setTimeout(() => this.recordTime = 0, 1000)
                this.$emit('close')
            }
        }
    },
    computed: {
        recordTimeText() {
            let minutes = Math.max((this.recordTime / 60) || 0, 0)
            let seconds = Math.max((this.recordTime % 60) || 0, 0)
            minutes = Math.floor(minutes).toString().padStart(2, '0')
            seconds = seconds.toString().padStart(2, '0')
            return `${minutes}:${seconds}`
        },
        classes() {
            let isActive = this.recordingAudioMessage || (this.sendingProgress && this.sendingProgress !== 100)
            return { 'audio-active': isActive }
        },
        progressBarStyle() {
            let barWidth = this.sendingProgress*3 + 'px'
            return {'width': barWidth}
        },
        ...mapGetters(USERDATA, [GET_ACTIVE_MICROPHONE]),
    },
    mounted() {
        this.recordAudio()
    },
    methods: {
        async recordAudio() {
            await this[ACT_REQUEST_MEDIA_DEVICES_ACCESS]({ audio: true, video: false })
            if (!this[GET_ACTIVE_MICROPHONE]) {
                this.modalOpen({
                    name: 'alert',
                    props: {
                        title: this.$t('errors.error'),
                        text: this.$t('no-mic')
                    }
                });
                return this.$emit('close');
            }
            const processorBlob = new Blob([AudioMsgRecorder], { type: 'text/javascript' });
            const processorURL = URL.createObjectURL(processorBlob);
            let { audioContext, audioWorkletNode } = await createWorkletNode(processorURL, 'audio-msg-recorder')
            this.recordAudioContext = audioContext
            this.recordAudioWorkletNode = audioWorkletNode

            const analyser = audioContext.createAnalyser()
            analyser.fftSize = 512
            const bufferLength = analyser.frequencyBinCount
            const dataArray = new Uint8Array(bufferLength)

            try {
                this.recordStream = await navigator.mediaDevices.getUserMedia({audio: true, video: false})
                this.recordStreamNode = audioContext.createMediaStreamSource(this.recordStream)
                this.recordStreamNode.connect(analyser).connect(audioWorkletNode).connect(audioContext.destination)
                this.recordingAudioMessage = true
            } catch (e) {
                console.log(`recordAudio error ${e.message}`)
                this.$emit('close')
                throw e
            }

            let c_w, c_h, canvasCtx
            this.$nextTick(() => {
                const canvas = document.getElementById('audio-spectrum')
                this.canvas = canvas
                c_w = canvas.width
                c_h = canvas.height
                canvasCtx = canvas.getContext("2d")
                canvasCtx.clearRect(0, 0, c_w, c_h)

                const drawBars = () => {
                    // requestAnimationFrame(drawBars)
                    analyser.getByteFrequencyData(dataArray)
                    const isDarkTheme = this.$store.getters['clientdata/getTheme'] === 'dark'
                    isDarkTheme ? canvasCtx.fillStyle = "#151e27" : canvasCtx.fillStyle = "#fff"
                    canvasCtx.fillRect(0, 0, c_w, c_h)

                    const barWidth = (c_w / bufferLength) * 2.5
                    let x = 0;

                    for (let i = 0; i < bufferLength; i++) {
                        const barHeight = dataArray[i]
                        canvasCtx.fillStyle = "rgb(" + (barHeight - 150) + ",121,191)"
                        canvasCtx.fillRect(
                            x,
                            c_h - barHeight / 2,
                            barWidth,
                            barHeight / 2
                        )
                        x += barWidth + 1
                    }
                }
                this.drawBarsInterval = setInterval(() => { drawBars() }, 25)
            })

            this.disablingWindow = document.querySelector('#disabled');
            this.disablingWindow.style.display = 'block';
            this.recordAudioInterval = setInterval(() => {
                analyser.getByteFrequencyData(dataArray)
                this.recordTime += 1;
            }, 1000);

        },
        async cancelVoiceMessage (e) {
            await this.clearVoiceMessage()
            this.recordTime = 0
            this.disablingWindow.style.display = 'none'
            setTimeout(() => this.recordTime = 0, 1000)
            this.$emit('close')
        },
        async sendVoiceMessage (e) {
            if (!this.recordTime || this.recordTime < 1) return
            let buffer = await new Promise((resolve, reject) => {
                if (!this.recordAudioWorkletNode) reject()

                this.recordAudioWorkletNode.port.onmessage = (e) => {
                    if (e.data.eventType === 'stopped') resolve(e.data.buffer)
                }

                this.recordAudioWorkletNode.port.postMessage({type: 'stop'})
            })


            try {
                const voiceBlob = new Blob([buffer], { type: 'audio/wav' })
                this.$emit('voice-msg', voiceBlob)
            } catch (e) {}

            await this.clearVoiceMessage()
        },
        async clearVoiceMessage() {
            this.recordStream && this.recordStream.getTracks().forEach(track => track.stop())
            this.recordStreamNode && this.recordStreamNode.disconnect()
            this.recordAudioWorkletNode && this.recordAudioWorkletNode.disconnect()
            this.recordAudioWorkletNode = null
            this.recordAudioContext = null
            this.recordingAudioMessage = false
            clearInterval(this.recordAudioInterval)
            this.recordAudioInterval = null
            clearInterval(this.drawBarsInterval)
        },
        ...mapActions(USERDATA, [ACT_REQUEST_MEDIA_DEVICES_ACCESS]),
    }
}
